<template>
  <component
    :is="compontentType"
    :to="redirectionLink"
    class="button-outline no-outline block py15 px40 bg-cl-transparent h4 weight-600 brdr-50px no-underline sans-serif fs-medium"
    :class="{
      light : color === 'light', 'brdr-white' : color === 'light', 'cl-white' : color === 'light',
      dark : color === 'dark', 'brdr-darkgray' : color === 'dark', 'cl-secondary' : color === 'dark',
      px0 : link ? true : false,
      px40 : link ? false : true
    }"
  >
    <slot>Button</slot>
  </component>
</template>

<script>
import focusClean from 'theme/components/theme/directives/focusClean'

export default {
  name: 'ButtonOutline',
  directives: { focusClean },
  props: {
    color: {
      type: String,
      default: '',
      required: false
    },
    link: {
      type: String,
      default: null,
      required: false
    }
  },
  computed: {
    compontentType () {
      return this.link ? 'router-link' : 'button'
    },
    redirectionLink () {
      return this.link ? this.localizedRoute(this.link) : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$dark-border: color(secondary);
$white: color(white);
$black: color(black);
$main: color(main);

.button-outline {
  border: 2px solid $main;
  // height: 62px;
}

@media screen and (min-width: 768px) {
  .button-outline {
    border: 3px solid $main;
    min-width: 250px;
  }
}
.dark {
  color: $main;
  border: 3px solid $main;
  &:hover,
  &:focus {
    color: $white;
    background: $main;
    border-color: $main;
  }
}
.light {
  border-color: $white;
  color: $white;
  &:hover,
  &:focus {
    color: $main;
    background: $white;
    border-color: $white;
  }
}
</style>
